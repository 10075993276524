import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CasinoActionCreators } from "../redux/action-creators";

export const useIsCasinoApp = () => {
  const dispatch = useDispatch();
  /**
   * This is a workaround to listen to the isSIC property change
   */
  useEffect(() => {
    Object.defineProperty(window, "isSIC", {
      set: function (value) {
        dispatch(CasinoActionCreators.setIsSIC(value));
      }
    });
  }, [dispatch]);
};
