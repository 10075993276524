import { useEffect } from "react";
import { useSelector } from "react-redux";
import { CasinoSelectors, UserContextSelectors } from "../redux/selectors";
import { PageContextUtils } from "../utils";
import { Theme, ThemeClass } from "../constants";

export const useDocumentClasses = () => {
  const isAuthenticated = useSelector(UserContextSelectors.isAuthenticated);
  const brandId = useSelector(UserContextSelectors.brandId);
  const isNativeIOS = useSelector(UserContextSelectors.isNativeIOS);
  const isIOS = useSelector(UserContextSelectors.isIOS);
  const isSIC = useSelector(CasinoSelectors.isSIC);

  useEffect(() => {
    const { currentName = Theme.Default } = PageContextUtils.getThemeData();
    let bodyThemeClass = ThemeClass.Default;

    switch (currentName) {
      case Theme.SportsIllustrated:
        bodyThemeClass = ThemeClass.SportsIllustrated;
        break;
      case Theme.Poker:
        bodyThemeClass = ThemeClass.Poker;
        break;
      case Theme.MrGreen:
        bodyThemeClass = ThemeClass.MrGreen;
    }

    document.body.classList.add(bodyThemeClass);
  }, []);

  useEffect(() => {
    window.onpageshow = event => {
      console.log("onpageshow", event);
      if (event.persisted) {
        window.location.reload();
      }
    };

    const root = document.getElementById("uc-container");
    if (root) {
      root.classList.add(`brand-${brandId}`);
    }
  }, [brandId]);

  useEffect(() => {
    if (isNativeIOS || (isSIC && isIOS))
      document.body.classList.add("iOS_native");
    else document.body.classList.remove("iOS_native");
  }, [isIOS, isNativeIOS, isSIC]);

  useEffect(() => {
    if (isAuthenticated) document.body.classList.add("authenticated");
    else document.body.classList.remove("authenticated");
  }, [isAuthenticated]);
};
