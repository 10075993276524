import keyMirror from "keymirror";

export const AppType = {
  Game: "Game"
};

export const SiteConstants = {
  DEFAULT_TIMEOUT: 30000
};

export const HttpVerb = keyMirror({
  POST: null,
  GET: null,
  PUT: null,
  PATCH: null,
  DELETE: null
});

export const Channel = {
  CRM: "crm",
  CROSS_SELL: "crossSell",
  IDENTITY: "identity",
  LOYALTY_INDICATION: "loyaltyIndication",
  MINI_CASHIER: "miniCashier",
  NAVIGATION: "navigation",
  PAYMENT: "payments",
  PLAYER: "player",
  ROUTER: "router",
  VIEW_INJECTOR: "viewInjector",
  UI: "ui",
  USER_INTERACTION: "userinteraction",
  WALLET: "wallet",
  SPORT: "sport",
  TRACKING: "tracking",
  SESSION: "session",
  CUSTOMER_RELATIONS: "customerRelations",
  NATIVE_CHANNEL: "native",
  GEOLOCATION_CHANNEL: "geolocation",
  BIOMETRICS: "biometrics",
  POST_LOGIN: "postLogin",
  PERFORM_ACTION: "performAction"
};

export const Topic = keyMirror({
  //WALLET
  BalanceUpdated: null,
  //PLAYER
  CTA_MyAccount_Open: null,
  CTA_MyAccount_Open_Challenges: null,
  CTA_MyAccount_Open_Reports: null,
  CTA_RICH_Open: null,
  CTA_NRS_Open: null,
  CTA_Open_LoyaltyIndication: null,
  playerPreferences: null,
  showBetslip: null,
  addToBetslip: null,
  openNativeApp: null,
  confirmLocation: null,
  loginCompletion: null,
  registerCompletion: null,
  favouriteSports: null,
  favouriteUpdate: null,
  TOURNAMENT_TICKETS: null,
  //IDENTITY CHANNEL
  loginSuccess: null,
  logoutSuccess: null,
  logout: null,
  handleShowLogin: null,
  preLogoutSuccess: null,
  //PAYMENTS
  CTA_Cashier_Open: null,
  CTA_Quick_Deposit: null,
  //UI
  DeviceUpdate: null,
  DeviceInformation: null,
  DialogShow: null,
  Dialog: null,
  handleExpandableCategory: null,
  CreateAppContainer: null,
  RemoveAppContainer: null,
  ShowAppError: null,
  OpenTouchidSettingsPage: null,
  Toast: null,
  messageDisplayed: null,
  messageActionTaken: null,
  webLoginShow: null,
  webLoginLoaded: null,
  controlCenterShow: null,
  scrollActivation: null,
  scrollToTop: null,
  toggleUserArea: null,
  nickNameChanged: null,
  //User interaction,
  Regulation_Click: null,
  themeModeUpdate: null,
  toggleAppContainer: null,
  appContainerReady: null,
  clientStateChanged: null,
  //CROSS SELL
  CTA_CasinoClient_Open: null,
  CTA_SportClient_Open: null,
  CTA_PokerClient_Open: null,
  //MINI CASHIER
  DetailsRetrieved: null,
  SetHeight: null,
  SetDirty: null,
  //CRM
  CTA_ExternalLink_Open: null,
  OPEN_SURVEY: null,
  TOURNAMENT_TICKETS_TAB_OPENED: null,
  //LOYALTY INDICATION
  INIT_LOYALTY_INDICATION: null,
  //VIEW INJECTOR
  SetVisible: null,
  //USER INTERACTION
  oddsFormatUpdate: null,
  hapticFeedback: null,
  BurgerMenu_Toggle: null,
  azSportsToggle: null,
  //SPORT
  betsUpdate: null,
  preOpenMyBets: null,
  openMyBets: null,
  //ROUTER
  Navigate: null,
  NavigateByCode: null,
  //TRACKING
  sendAnalytics: null,
  sendEvent: null,
  //SESSION
  clientInit: null,
  clientInitSucceeded: null,
  appClosed: null,
  appClosing: null,
  appInitSucceeded: null,
  appInitFailed: null,
  appError: null,
  webLoginClosed: null,
  //NAVIGATION
  actionInterrupted: null,
  changeBrowserURI: null,
  overlayClicked: null,
  performAction: null,
  flowRunner: null,
  openClientPage: null,
  //CUSTOMRE RELATIONS
  CTA_CustomerRelations_Open: null,
  //NATIVE_CHANNEL
  SaveTouchIdToken: null,
  SaveSuggestedStatus: null,
  nativeInit: null,
  //GEOLOCATION_CHANNEL
  initGeolocationFlow: "initGeolocationFlow",
  startedPluginDownload: "startedPluginDownload",
  closeInstallationPopup: "closeInstallationPopup",
  userInitiatedRetry: "userInitiatedRetry",
  //BIOMETRICS
  biometricSettings: null,
  biometricsFlow: null,
  biometricsStatus: null,
  enrollmentStatus: null,
  enrollmentFinished: null,
  //Promotion Eligibility
  ELIGIBILITY_DATA: null,
  //Post Login
  completeAction: null
});

export const DialogType = keyMirror({
  Hide: null,
  Logout: null,
  LowFunds: null,
  TimeoutAndKick: null,
  UploadDocuments: null,
  Restriction: null,
  Migration: null,
  ConfirmLocation: null,
  StateSwitcher: null,
  LocationConfirmation: null,
  GeoComplyRequired: null,
  GeolocationConfirmed: null,
  GeolocationProcess: null,
  GeolocationTroubleShooting: null,
  GeolocationMobilePermissions: null,
  GeolocationNotInUSA: null,
  GeolocationRetryInProgress: null,
  GeolocationVerify: null,
  Panic: null,
  PersonalLimits: null,
  TimeSpentReminder: null,
  ActivityHistory: null,
  ShowPlayerWinLoss: null,
  RedirectMft: null,
  RealityCheck: null,
  PokerDownloadPcGuide: null,
  PokerDownload: null,
  NightlyLimitNotice: null,
  NightlyLimitRestriction: null,
  NightlyLimitRestricted: null,
  DownloadApp: null,
  EnvAccessAuthentication: null,
  LugasLogout: null,
  TechnicalProblem: null,
  AppUpgradeRequired: null,
  NewPlatform: null,
  ShowOnboarding: null
});

export const PostLoginAction = keyMirror({
  LowFunds: null,
  UploadDocuments: null,
  InitGeolocationFlow: null
});

export const EventCategory = {
  SEARCH: "Search",
  LEFT_MENU: "Left Menu",
  USER_AREA: "User Area"
};

export const EventAction = {
  CLICK: "Click"
};

export const DeviceType = keyMirror({
  Mobile: null,
  PC: null
});

export const Actions = {
  openPromotions: "openPromotions",
  openCashier: "openCashier",
  deposit: "deposit",
  openMyAccount: "openMyAccount",
  openRich: "openRich",
  navigateClientPage: "navigateClientPage",
  openControlCenterFull: "openControlCenterFull",
  openCIS: "openCIS"
};

export default {
  Channel,
  DeviceType,
  EventCategory,
  HttpVerb,
  SiteConstants,
  Topic
};

export const HEADER_CLASSES = {
  WIDTH_WIDE_SCREEN: "wide-screen",
  WIDTH_SMALL_SCREEN: "mobile-screen",
  WIDTH_REGULAR_SCREEN: ""
};

export const AuthenticationScenario = {
  PostLogin: "postlogin",
  Prelogin: "prelogin"
};

export const ParentSubdomainRegex = "^/[^/]+";

/**
 * I reordered it, there is more than single value for the same enum please confirm before using..
 * */
export const MyAccountTab = {
  ControlCenter: "1",
  ResponsibleGaming: "1",
  ChangePassword: "2",
  RewardsPWC: "3",
  Bonuses: "3",
  PersonalDetails: "4",
  Reports: "6",
  AccountStatement: "6",
  Challenges: "7",
  Redeem: "8",
  TRT: "10",
  Tickets: "10",
  FreePlay: "11",
  FreeBets: "12",
  MyPoints: "13",
  Races: "14",
  Leaderboard: "15",
  MyChallenges: "16",
  Notifications: "17",
  IdentityVerification: "18",
  LoginNotifications: "19",
  FreeBetsSpectate: "20",
  Security: "21",
  SiVouchers: "22",
  SecurityCenter: "24",
  UserAgreement: "25",
  SportOffers: "26",
  WinLossReports: "27",
  AccountDetails: "28"
};

export const CashierTab = {
  Deposit: "1",
  History: "2",
  Cashout: "4",
  DepositHistory: "5",
  CashoutHistory: "6",
  BonusHistory: "7",
  Redeem: "8",
  MoneyTransfer: "10",
  FullMoneyTransfer: "11",
  BlockedDeposit: "12",
  OnlyDeposit: "13",
  OnlyCashout: "14",
  OnlyVerifyID: "15",
  QuickDeposit: "16",
  OnlyPersonalize: "17",
  OnlySelfExclusion: "18",
  MobileDeposit: "19",
  VerifyID: "20",
  MobileVerifyID: "21",
  MobileCashout: "22",
  MobileMoneyTransfer: "23",
  MobilePersonalize: "24",
  OnlyBankDetails: "25"
};

export const SurveyId = "uc-survey";

export const EagleMigrationStateRedirect = 21;
export const EagleRegisterLink =
  "https://eagle.test.wlscasino.com/login?tmpLoginId=fd9DS0ds0Akfds03LJhjfds";
export const EagleIOSAppLink =
  "https://apps.apple.com/us/app/winner/id1531529695";
export const EagleAndroidAppLink =
  "https://play.google.com/store/apps/details?id=ro.winner.casino&hl=en";

export const WIDTH_TRANSITION_VALUE = 1279;

export const HEADER_TRANSITION_BREAKPOINT = {
  WIDTH_TRANSITION_VALUE: WIDTH_TRANSITION_VALUE,
  WIDTH_WIDE_SCREEN: 1480,
  WIDTH_SMALL_SCREEN: WIDTH_TRANSITION_VALUE
};

export const SCREEN_TYPE = {
  MOBILE: 1,
  WIDE: 2,
  REGULAR: 3
};

export const ROOT = "/";
export const SC_HOME = "#/home";

export const CustomerRelationsType = {
  Chat: 1,
  Email: 2,
  OnlineHelp: 3
};

export const IsCasinoAutoLogin = "IsCasinoAutoLogin";

export const SessionStorageItems = {
  OddsFormat: "oddsFormat",
  ShowDevTools: "showDevTools",
  IsTouchIdAutoLogin: "isTouchIdAutoLogin",
  IsClientMode: "IsClientMode",
  CashierPromoCode: "cashierPromoCode"
};

export const CategoryType = {
  HomeSection: 1,
  Sports: 2
};

//Current. This should be used over SportProviderEnum
export const ClientProviderEnum = {
  Kambi: "Kambi",
  Spectate: "Spectate",
  Poker: "Poker"
};

export const AppIdentifier = {
  FullCashier: "PAYMENTS_FULLCASHIER",
  UnifiedCashier: "PAYMENTS_DEPOSIT",
  Withdrawal: "PAYMENTS_CASHOUT",
  ControlCenterMini: "RG_CONTROL_CENTER_MINI",
  ControlCenterFull: "RG_CONTROL_CENTER_FULL"
};

export const PaymentAppType = {
  FullCashier: "FullCashier",
  UnifiedCashier: "UnifiedCashier"
};

export const NativeAppType = {
  Sport888: "Sport888"
};

export const Theme = {
  Default: "default",
  SportsIllustrated: "SportsIllustrated",
  Poker: "Poker",
  MrGreen: "MrGreen"
};

export const CookieType = {
  UserSelectedState: "userSelectedState"
};

export const CloseSessionReason = {
  ClientLeave: 0
};

export const OpeningMode = {
  Popup: "popup",
  RightSideDrawer: "rightSideDrawer",
  FullScreenPopup: "fullScreenPopup",
  MainView: "mainView",
  bubble: "bubble"
};

export const IgnoreClickOutsideAttribute = "data-ignore-click-outside";

export const MessageActionType = {
  Show: "show",
  Close: "close",
  Confirmation: "confirmation",
  Cancel: "cancel"
};

export const PersonalLimits = {
  Playtime: "PlayTimeLimit",
  Deposit: "DepositLimit",
  Bankroll: "BankrollLimit"
};

export const PersonalLimitsPopupType = {
  Toast: "Toast",
  Popup: "Popup"
};

export const CategoriesIconsSvgId = "categoriesIconsSvg";
export const KambiCategoriesIconsSvgId = "sportclient-icons-svg";

export const DebounceConstants = {
  Search: 50, //ms
  Icons: 500 //ms
};

export const ScreenOrientation = {
  Portrait: "portrait",
  Landscape: "landscape"
};

export const NavigationLinkId = {
  ToSportOffers: "1",
  ToTouchId: "20"
};

export const NotificationStatus = {
  Active: 5,
  Expired: 4
};

export const RegulationTypeIds = {
  UK: 255,
  Denmark: 8,
  France: 5,
  Germany: 11,
  GermanyDe: 12,
  Gibraltar: 4,
  Italy: 1,
  Malta: 16
};

export const ClientPageType = {
  onlineHelp: "online-help",
  rgPolicy: "rg-policy"
};

export const RightSideDrawerCloseReason = {
  OutsideClick: "User closed app",
  ManualXBtn: "User clicked the X button"
};

export const WidgetActionCloseSuccess = {
  PAYMENTS_DEPOSIT: "close deposit widget",
  PAYMENTS_CASHOUT: "close cashout widget",
  PAYMENTS_PLAYER_TRANSACTIONS_HISTORY: "close transaction history widget",
  CR_CHAT: "close chat widget",
  CR_HELP_CENTER: "close help widget",
  RG_CONTROL_CENTER_FULL: "close control center widget"
};

export const WidgetActionCloseError = {
  PAYMENTS_DEPOSIT: "close deposit widget error screen",
  PAYMENTS_CASHOUT: "close cashout widget error screen",
  PAYMENTS_PLAYER_TRANSACTIONS_HISTORY:
    "close transaction history widget error screen",
  CR_CHAT: "close chat widget error screen",
  CR_HELP_CENTER: "close help widget error screen",
  RG_CONTROL_CENTER_FULL: "close control center widget error screen"
};

export const MenuItemComponentType = {
  ExpandableSportCategoryMenuItem: "ExpandableSportCategoryMenuItem",
  SportCategoryMenuItem: "SportCategoryMenuItem",
  KambiAllSportsMenuItem: "KambiAllSportsMenuItem",
  ExpandableSportCategoryMenuItemMobile:
    "ExpandableSportCategoryMenuItemMobile",
  KambiMobileExpandMenuItem: "KambiMobileExpandMenuItem"
};

export const CATEGORY_CONSTANTS = {
  NUMBER_OF_ITEMS_MOBILE_LOW: 7,
  LOW_NUMBER_OF_ITEMS_SPECTATE: 4,
  HIGH_NUMBER_OF_ITEMS_SPECTATE: 7,
  MAX_NUMBER_OF_ITEM_KAMBI: 7,
  LOW_NUMBER_OF_ITEMS_KAMBI: 3,
  SPECTATE_NUMBER_OF_CATEGORIES: 5
};

export const DrawerType = {
  AppearanceMenu: "AppearanceMenu",
  HamburgerMenu: "HamburgerMenu",
  LanguageMenu: "LanguageMenu",
  OddsMenu: "OddsMenu",
  QuickLinksInnerMenu: "QuickLinksInnerMenu",
  MyAccountArea: "MyAccountArea",
  SettingsArea: "SettingsArea"
};

export const ThemeClass = {
  MrGreen: "mrg-theme",
  Default: "default-theme",
  SportsIllustrated: "si-theme",
  Poker: "poker-theme"
};

export const VerticalTransition = {
  Left: "left",
  Right: "right"
};

export const ButtonsVariant = {
  Outlined: "outlined",
  Contained: "contained",
  Underlined: "underlined"
};

export const Rotation = {
  Default: 0,
  "90deg": "90deg",
  "180deg": "180deg",
  "270deg": "270deg"
};

export const CSSVarNames = {
  HeaderPC: "--header-height",
  HeaderMobile: "--mobile-header-height",
  ClientTopGap: "--client-top-gap",
  BottomNavbarMobileGap: "--bottom-navbar-mobile-gap"
};

export const SessionUpdateInterval = 30 * 1000;

export const UploadDocumentsDepositLimits = {
  DefaultLimit: 10000,
  HighRiskLimit: 2200,
  LowRiskLimit: 4000
};
