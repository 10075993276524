import { Channel, Topic } from "../constants";
import { useCallback, useEffect } from "react";

import { MessageBrokerUtils } from "../utils";
import { UserContextActionCreators } from "../redux/action-creators";
import { useDispatch } from "react-redux";

export const useNickNameChanged = () => {
  const dispatch = useDispatch();

  const handleNickNameChanged = useCallback(
    newNickname => {
      dispatch(UserContextActionCreators.updateNickName(newNickname));
    },
    [dispatch]
  );

  useEffect(() => {
    const nickNameChangedSubscription = MessageBrokerUtils.subscribe({
      channel: Channel.UI,
      topic: Topic.nickNameChanged,
      callback: handleNickNameChanged
    });

    return () => {
      nickNameChangedSubscription?.unsubscribe();
    };
  }, [handleNickNameChanged]);
};
