import { useEffect, useState, useCallback } from "react";
import { DeviceUtils } from "../utils/deviceUtils";
import { AppFeatureUtils } from "../utils";
import { useSelector } from "react-redux";
import { CasinoSelectors, UserContextSelectors } from "../redux/selectors";

export const useBottomBar = () => {
  const [isDisplayedButtonBar, setIsDisplayedButtonBar] = useState(false);

  const isBottomNavigationEnabled = AppFeatureUtils.isBottomNavigationEnabled();
  const isMobile = useSelector(UserContextSelectors.isMobile);
  const isNative = useSelector(UserContextSelectors.isNative);
  const isSIC = useSelector(CasinoSelectors.isSIC);
  const isSIP = DeviceUtils.isPokerApp();

  const evaluateFlags = useCallback(() => {
    const _isDisplayedButtonBar =
      isBottomNavigationEnabled && isMobile && !isNative && !isSIC && !isSIP;
    setIsDisplayedButtonBar(_isDisplayedButtonBar);
  }, [isBottomNavigationEnabled, isMobile, isNative, isSIC, isSIP]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      evaluateFlags();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [evaluateFlags]);

  return {
    isDisplayedButtonBar
  };
};
